
function sizeBottomSpacer() {
	/*
	$('.page').css('height', 'auto');
	if ( parseInt( $('body').width() ) < 400 ) {
		var h = parseInt( $( window ).height() ) - 105 - parseInt( $('#footer-outer').height() ) - 8;
		if ( h > parseInt( $('.page').height() ) ) {
			$('.page').css('height', h + 'px');
		}
	}
	$('.page').first()
	.find('.spacer').first()
		.css('height', parseInt( $('#footer-outer').height() ) + 'px');
	*/

	$('#footer-outer').css('margin-top', '0px');
	$('#footer-outer').css('margin-top', Math.max(0, (parseInt($(window).height()) - (parseInt($('body').height())+90)) ) + 'px');
}

$( document ).ready(function(){

	$('#menu-outer, #logo-outer').on('mouseover', function(){
		$('#menu-outer').addClass('over');
	}).on('mouseout', function(){
		$('#menu-outer').removeClass('over');
	});

	// Mobile menu toggle
	$('a.menu-toggle').on('click', function(e){
		e.preventDefault();
		e.stopImmediatePropagation();
		$('#menu-inner').add( this ).toggleClass('open');
	});
	$('#menu-inner').on('click', function(e){
		$('a.menu-toggle').add( this ).removeClass('open');
	});
	
	// Size spacer
	$( window ).on('resize', function() {
		sizeBottomSpacer();
	});
	setTimeout(function(){
		sizeBottomSpacer();
	}, 500);

});